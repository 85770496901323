<template>
    <div>
        <v-title title="FastEditor"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>FastEditor</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template>
                <div class="row mt-3">
                    <div class="col-6">
                        <label>Search for Slug</label>
                        <quick-search :quickSearch.sync="filters.search" />
                    </div>
                    <div class="col-6">
                        <pagination :pagination="pagination" @change="changeAmountOfItems" />
                    </div>
                </div>

                <merchant-data-table class="shadow" :options="options" :url="url" :quickSearch="filters.search" ref="table" @paginationSet="updatePagination" :isNotApplicationBound="true"></merchant-data-table>
            </template>
        </div>

        <mercur-dialog :is-open.sync="dialog.isOpen">
            <div class="mt-3 mb-1">{{ dialog.content }}</div>
            <template #footer>
                <div class="text-right">
                    <mercur-button class="btn" @click.native="dialog.isOpen = false">Cancel</mercur-button>
                    <mercur-button class="btn" @click.native="dialogAction" :disabled="isLoading">Confirm</mercur-button>
                </div>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'

export default {
    name: 'FastEditorView',
    components: {
        MerchantDataTable,
        QuickSearch,
        Pagination,
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.FAST_EDITOR.OVERVIEW,
            isDialogOpen: false,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Slug': { field: 'productSlug' },
                    'Sku': { field: 'prestaSku' },
                    'Status': { field: 'status' },
                    'Propagation Status': { field: 'propagationStatus' },
                    'Created': { field: 'dateCreated', sortable: true, filter: false },
                    'Updated': { field: 'dateUpdated', sortable: true, filter: false },
                },
                quickSearchColumns: ['productSlug'],
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
            },
        }
    },
    methods: {
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>
